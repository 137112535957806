import { createLoader, getCoords } from './helper';

class LoadMorePosts {
  constructor(postsContainer) {
    this.postsContainer = document.getElementById(postsContainer);
    if (this.postsContainer) {
      this.container = document.getElementById('inspirations');
      this.scrollingContainer = window;
      this.isAjax = false;
      this.currentPage = 2;
      this.maxNumPages = 3;
      this.form = document.getElementById('inspiration-form');

      const containerTopPos = getCoords(this.container).top;
      this.scrollingContainer.addEventListener('scroll', () => {
        const containerBottomPos = this.container.clientHeight - containerTopPos;
        if (document.documentElement.scrollTop >= containerBottomPos) this.ajax(this.container);
      });
    }
  }

  ajax(postsContainer) {
    if (this.currentPage <= this.maxNumPages && !this.isAjax) {
      const loader = createLoader();
      this.isAjax = true;

      const formData = new FormData(this.form);
      formData.append('action', 'ajaxGetInspirations');
      formData.append('scroll', 'scroll');
      formData.append('paged', this.currentPage);

      fetch(wc_add_to_cart_params.ajax_url, {
        method: 'post',
        body: formData
      })
        .then(res => res.json())
        .then(res => {
          this.maxNumPages = res.max_num_pages;
          this.currentPage++;
          postsContainer.innerHTML += res.posts;
          this.isAjax = false;
          loader.remove();
        });
    }
  }
}

export default LoadMorePosts;
