export default class SearchField {
  constructor() {
    this.button = document.getElementById('header-search-button');
    this.field = document.getElementById('header-search-input');
    this.overlay = document.getElementById('overlay');
    this.toggleButton();
    this.closeField();
  }

  toggleButton() {
    this.button.addEventListener('click', e => {
      e.preventDefault();

      this.field.classList.toggle('active');
      if (this.field.classList.contains('active')) {
        this.field.focus();
        this.overlay.classList.add(...['active', '--transparent']);
        this.overlay.style.zIndex = 98;
      }
    });
  }

  closeField() {
    this.overlay.addEventListener('click', () => {
      this.field.classList.remove('active');
      this.overlay.classList.remove(...['active', '--transparent']);
      this.overlay.style.zIndex = 999;
    });
  }
}
