import { createLoader } from './../helper';

class InspirationFilters {
  constructor() {
    this.form = document.getElementById('inspiration-form');
    if (this.form) {
      this.options = this.form.querySelectorAll('#inspiration-form .option-filter');
      this.inspirationContainer = document.getElementById('inspirations');
      this.init();
    }
  }

  init() {
    const formData = new FormData(this.form);
    formData.append('action', 'ajaxGetInspirations');

    const loader = createLoader(this.inspirationContainer.parentElement);
    this.ajax(formData, loader);

    this.options.forEach(option => {
      option.addEventListener('change', () => {
        if (option.value == '*') {
          [...this.options].filter(el => {
            if (el != option) {
              el.checked = false;
            }
          });
        }
        const formData = new FormData(this.form);
        formData.append('action', 'ajaxGetInspirations');
        const loader = createLoader(this.inspirationContainer.parentElement);
        this.ajax(formData, loader);
      });
    });
  }

  ajax(formData, loader) {
    fetch(wc_add_to_cart_params.ajax_url, {
      method: 'post',
      body: formData
    })
      .then(res => res.json())
      .then(res => {
        this.inspirationContainer.innerHTML = res.posts;
        loader.remove();
      });
  }
}

export default InspirationFilters;
