export const getScreenWidth = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth;

  return width;
};

export const getScreenHeight = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    height = w.innerHeight || e.clientHeight || g.clientHeight;

  return height;
};

export const isMobile = () => {
  if (getScreenWidth() <= 768) return true;
};

export const isMobileNotTablet = () => {
  if (getScreenWidth() <= 766) return true;
};

export const isPhone = () => {
  if (getScreenWidth() <= 440) return true;
};

export const getCoords = elem => {
  let box = elem.getBoundingClientRect();

  let body = document.body;
  let docEl = document.documentElement;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  let clientTop = docEl.clientTop || body.clientTop || 0;
  let clientLeft = docEl.clientLeft || body.clientLeft || 0;

  let top = box.top + scrollTop - clientTop;
  let left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
};

export const createLoader = (container = document.body) => {
  if (container) {
    const loader = document.createElement('div');

    loader.classList.add('loader');
    container == document.body && loader.classList.add('fixed');

    if (window.getComputedStyle(container).position == 'static') container.style.position = 'relative';

    return container.appendChild(loader);
  }
};

export const createNotice = (message, type = 'info') => {
  const notice = document.createElement('div');

  notice.classList.add(...['woocommerce-message', 'active', type]);
  notice.innerHTML = message;

  setTimeout(() => {
    notice.classList.remove('active');
  }, 4000);

  setTimeout(() => {
    notice.remove();
  }, 4300);

  return document.querySelector('.woocommerce-notices-wrapper').appendChild(notice);
};

export const dataURItoBlob = dataURI => {
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  let mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const b64toBlob = dataURI => {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
};
