import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';
import WallConfiguratorProduct from './WallConfiguratorProduct';
import queryString from 'query-string';
import { createLoader } from './../helper';

class WallConfigurator {
  constructor() {
    this.imageContainer = document.getElementById('configurator-image');
    this.actionForm = document.querySelector('#wall-configurator-form');
    this.productContainer = this.actionForm.querySelector('#configured-product-content');
    this.currentProductID = 0;
    if (this.imageContainer && this.actionForm) {
      this.canvas = document.getElementById('wall-configurator-product');
      this.formData = new FormData(this.actionForm);
      this.init();
    }
  }

  init() {
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      for (let i of searchParams) {
        const input = this.actionForm.querySelector(`input[name*="${i[0]}"][value="${i[1]}"]`);
        if (input) input.checked = true;
        else
          this.actionForm
            .querySelector(`input[name*="${i[0]}"]`)
            .closest('.options')
            .querySelector('label input').checked = true;
      }
    } else {
      // set first option if search params are
      const inputs = this.actionForm.querySelectorAll('.option label:first-child input[type="radio"]');
      for (const input of inputs) {
        input.checked = true;
      }
      this.changeOptionGroup('input[name="configurator[model]"]', '.layouts');
      this.newFormData();
      this.setQueries(this.formData);
    }

    this.changeOptionGroup('input[name="configurator[model]"]', '.layouts');
    this.changeOptionGroup('input[name="configurator[model]"]', '.colors');
    this.newFormData();
    this.slider();
    this.getImage(this.formData);
    this.onChangeForm();
    this.setCurrentProduct();
    this.setConfiguredProductContent();
    new WallConfiguratorProduct(this.canvas, this.currentProductID);
  }

  onChangeForm() {
    const inputs = this.actionForm.querySelectorAll('input');

    for (const input of inputs) {
      input.addEventListener('change', () => {
        this.newFormData();
        this.getImage(this.formData);
        this.setQueries(this.formData);
      });
    }
  }

  changeOptionGroup(trigger, groupSelector) {
    const models = this.actionForm.querySelectorAll(trigger);
    this.showOptionGroup(groupSelector, queryString.parse(location.search).model);

    for (const model of models) {
      model.addEventListener('change', () => {
        this.showOptionGroup(groupSelector, model.value);
      });
    }

    this.setQueries(this.newFormData());
  }

  showOptionGroup(groupSelector, value) {
    const optionsGroup = this.actionForm.querySelectorAll(`${groupSelector} label`);

    for (const optionGroup of optionsGroup) {
      let models = optionGroup.getAttribute('data-product-id').split(',');

      if (models.includes(value)) {
        optionGroup.style.display = 'inline-block';
        this.actionForm.querySelectorAll(`${groupSelector} label`).forEach(el => el.classList.remove('active'));
        this.actionForm.querySelector(`${groupSelector} label[data-product-id*="${value}"] input`).checked = true;
      } else optionGroup.style.display = 'none';
    }
  }

  getImage(formData) {
    const loader = createLoader(this.imageContainer);

    return fetch(wc_add_to_cart_params.ajax_url, {
      method: 'POST',
      body: formData
    })
      .then(res => res.json())
      .then(res => {
        $(this.imageContainer).slick('removeSlide', null, null, true);
        $(this.imageContainer).slick('slickAdd', res.template);
      })
      .then(() => loader.remove());
  }

  setQueries(formData) {
    let query = {};
    for (let pair of formData.entries()) {
      if (pair[0] != 'action') {
        const key = pair[0].replace('configurator[', '').replace(']', '');
        query[key] = pair[1];
      }
    }

    const q = queryString.stringify(query);
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + `?${q}`;
    window.history.pushState(
      {
        path: newUrl
      },
      '',
      newUrl
    );
  }

  slider() {
    $(this.imageContainer).slick({
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 5000,
      speed: 1000
    });
  }

  getOptionValue(name) {
    const elem = this.actionForm.querySelector(`[name*="${name}]"]:checked`);
    if (elem) return elem.value;
  }

  setCurrentProduct() {
    this.currentProductID = this.getOptionValue('model');

    for (const model of this.actionForm.querySelectorAll(`[name*="model"]`)) {
      model.addEventListener('change', () => {
        new WallConfiguratorProduct(this.canvas, model.value);
      });
    }
  }

  setConfiguredProductContent() {
    const ajax = formData => {
      return fetch(wc_add_to_cart_params.ajax_url, {
        method: 'post',
        body: formData
      })
        .then(res => res.json())
        .then(({ template }) => (this.productContainer.innerHTML = template));
    };

    this.newFormData('ajaxGetConfiguredProductContent');
    this.formData.append('configurator[model]', this.getOptionValue('model'));
    this.formData.append('configurator[color]', this.getOptionValue('color'));
    ajax(this.formData);

    this.actionForm.querySelectorAll('.models input, .colors input').forEach(el => {
      el.addEventListener('change', () => {
        this.newFormData('ajaxGetConfiguredProductContent');
        ajax(this.formData);
      });
    });
  }

  newFormData(action = null) {
    const formData = new FormData(this.actionForm);

    if (action) {
      formData.append('action', action);
    } else {
      formData.append('action', 'ajaxGetWallConfiguredImage');
    }

    for (let pair of formData.entries()) {
      const event = new CustomEvent('wallConfigurationOptions', {
        detail: {
          [pair[0]]: pair[1]
        }
      });
      window.dispatchEvent(event);
    }

    this.formData = formData;

    return this.formData;
  }
}

export default WallConfigurator;
