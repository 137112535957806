class Popup {
  constructor(button, popup) {
    this._button = document.querySelector(button);
    this._popup = document.querySelector(popup);
    this._overlay = document.querySelector('.overlay');
    this.init();
  }

  init() {
    if (this._button && this._popup) {
      this._button.addEventListener('click', () => {
        const popupID = this._popup.getAttribute('id');
        this._overlay.setAttribute('id', `overlay-${popupID}`);

        this._overlay.classList.add('active');
        this._popup.classList.add('active');

        // const openPopupEvent = new CustomEvent('openPopup');
        // window.dispatchEvent(openPopupEvent);

        this._overlay.addEventListener('click', () => {
          this.close();
        });
      });
    }
  }

  close() {
    if (this._popup != null) {
      this._popup.classList.remove('active');
    }
    this._overlay.classList.remove('active');

    // const closePopupEvent = new CustomEvent('closePopup');
    // window.dispatchEvent(closePopupEvent);
  }
}
export default Popup;
