const changeTotalPrice = () => {
  const priceElem = document.getElementById('total-price');
  if (priceElem) {
    document.getElementById('quantity').addEventListener('newInputValue', e => {
      const defaultPrice = priceElem.getAttribute('data-default-price');
      e.preventDefault();
      let newPrice = parseFloat(defaultPrice) * parseInt(e.detail);
      newPrice = newPrice.toFixed(2);
      priceElem.innerHTML = newPrice;
    });
  }
};

export default changeTotalPrice;
