import FloatLabels from './forms/float-labels';
import SearchField from './searchField';
import SiteHeader from './siteHeader';
import TabsImage from './tabsImage';
import { isMobile } from './helper';
import { toggleMenu, addSticky, closeMenu } from './navigation';
import numberInputButtons from './numberInputButtons';
import tabsPanel from './tabsPanel';
import changeTotalPrice from './woocommerce/single-product/changeTotalPrice';
import togglePanels from './togglePanels';
import VisualAccordion from './VisualAccordion';
import InspirationFilters from './inspirations/InspirationFilters';
import SingleInspiration from './inspirations/singleInspiration';
import LoadMorePosts from './loadMorePosts';
import WallConfigurator from './wall-configurator/WallConfigurator';
import Popup from './popup';

window.addEventListener('DOMContentLoaded', () => {
  toggleMenu();
  new SiteHeader({
    setBodyPaddingTop: document.body.classList.contains('page-template-homepage') ? false : true
  });
  new FloatLabels();
  new SearchField();
  new TabsImage();
  new tabsPanel('.tabs-panel');
  new VisualAccordion();
  new InspirationFilters();
  new SingleInspiration();
  if (document.getElementById('wall-configurator')) new WallConfigurator();
  new LoadMorePosts('inspirations');

  numberInputButtons('quanitity-input', '#less-products', '#more-products');
  changeTotalPrice();
  togglePanels();

  const ralColorsPopup = new Popup('#ral-colors-btn', '#popup-ral');
  const ncsColorsPopup = new Popup('#ncs-colors-btn', '#popup-ncs');
  window.addEventListener('selectedColor', () => {
    ralColorsPopup.close();
    ncsColorsPopup.close();
  });

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
