// https://github.com/kenwheeler/slick

import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';

/****** SLIDERS *******/
const sliders = {
  home: $('#home-slider'),
  products: $('#products-slider')
};

function homeSLider() {
  if (sliders.home.length) {
    const speedObj = {
      speed: 700,
      autoplaySpeed: 4000
    };

    sliders.home.slick(
      Object.assign(
        {
          arrows: false,
          autoplay: false,
          dots: false,
          pauseOnFocus: false,
          pauseOnHover: false,
          swipeToSlide: false
        },
        speedObj
      )
    );

    const nextSlideButton = document.getElementById('home-slider-actions');
    nextSlideButton.addEventListener('click', () => {
      sliders.home.slick('slickNext');
    });

    sliders.home.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      startProgressbar();
    });

    const slideTime = parseInt((speedObj.speed + speedObj.autoplaySpeed) / 1000);
    const progress = document.getElementById('home-slider-progress');

    let isPause, tick, percentTime;
    const startProgressbar = () => {
      resetProgressbar();
      percentTime = 0;
      isPause = false;
      tick = setInterval(interval, 10);
    };

    const interval = () => {
      if (isPause === false) {
        percentTime += 1 / slideTime;
        progress.style.width = percentTime + '%';
        if (percentTime >= 100) {
          sliders.home.slick('slickNext');
          startProgressbar();
        }
      }
    };

    const resetProgressbar = () => {
      progress.style.width = 0 + '%';
      clearTimeout(tick);
    };

    startProgressbar();
  }
}
homeSLider();

function productsSlider() {
  sliders.products.slick({
    arrows: false,
    slidesToShow: 4,
    autoplay: true,
    speed: 700,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
}

productsSlider();
