class tabsPanel {
  constructor(containers) {
    this.containers = document.querySelectorAll(containers);
    if (this.containers) {
      this.init();
    }
  }

  init() {
    this.containers.forEach(container => {
      this.setActivePanel(container, 0);
      container.querySelectorAll('.tabs-button').forEach((button, index) => {
        button.addEventListener('click', () => {
          button.classList.add('active');
          this.setActivePanel(container, index);
        });
      });
    });
  }

  setActivePanel(container, index) {
    container.querySelectorAll('.tabs-button').forEach(button => button.classList.remove('active'));
    container.querySelectorAll('.tabs-button')[index].classList.add('active');
    container.querySelectorAll('.toggled-content').forEach(el => el.classList.remove('active'));
    container.querySelectorAll('.toggled-content')[index].classList.add('active');
  }
}

export default tabsPanel;
