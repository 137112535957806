import { isMobileNotTablet } from './helper';

export default class TabsImage {
  constructor() {
    if (isMobileNotTablet()) {
      this.container = document.querySelector('.tabs-image-container.mobile');
    } else {
      this.container = document.querySelector('.tabs-image-container.desktop');
    }
    if (this.container) {
      this.menuItems = this.container.querySelectorAll('.tabs-menu .single-menu-item');
      this.sectionBgs = this.container.querySelectorAll('.section-bgs .box');
      this.setActive(0);
      this.toggleActive();
    }
  }

  setActive(index) {
    this.menuItems[index].classList.add('active');
    this.sectionBgs[index].classList.add('active');
  }

  removeActives() {
    this.menuItems.forEach(el => el.classList.remove('active'));
    this.sectionBgs.forEach(el => {el.classList.remove('active')});
  }

  toggleActive() {
    this.menuItems.forEach(el => {
      el.addEventListener('click', () => {
        this.removeActives();
        const index = [...this.menuItems].indexOf(el);
        this.setActive(index);
      });
    });
  }
}
