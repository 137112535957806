import { createLoader } from './../../helper';
import $ from 'jquery';

class ProductGallery {
  constructor() {
    this.galleryThumbnailsContainer = document.getElementById('product-gallery-thumbnails');
    if (this.galleryThumbnailsContainer) {
      this.colors = document.querySelectorAll('input[name="pa_kolor"]');
      this.init();
    }
  }

  init() {
    const formData = new FormData();
    formData.append('action', 'ajax_product_color_inspirations_gallery');

    window.addEventListener('selectedColor', e => {
      formData.append('color_id', e.detail.selectedColorID);
      formData.append('product_id', e.detail.productID);
      this.ajax(formData);
    });
  }

  ajax(formData) {
    const loader = createLoader(this.galleryThumbnailsContainer);

    return fetch(wc_add_to_cart_params.ajax_url, {
      method: 'post',
      body: formData
    })
      .then(res => res.json())
      .then(res => (this.galleryThumbnailsContainer.innerHTML = res.template))
      .then(() => {
        $(this.galleryThumbnailsContainer)
          .find('a[rel="product-inspiration-image"]')
          .fancybox({
            cyclic: true
          });
      })
      .then(() => loader.remove);
  }
}

export default ProductGallery;
