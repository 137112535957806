import ConfiguratorProduct from './../ConfiguratorProduct';

class WallConfiguratorProduct {
  constructor(canvas, productID) {
    this._canvas = canvas;
    this._productID = productID;
    this.init();
  }

  init() {
    const ProductConfigurator = new ConfiguratorProduct(this._canvas, this._productID);

    document.querySelectorAll('.colors input').forEach(el => {
      el.addEventListener('change', () => {
        let checkedModel = document.querySelector('.models input:checked');
        let modelID = checkedModel ? checkedModel.value : null;
        const event = new CustomEvent('selectedColor', {
          detail: {
            selectedColorID: el.getAttribute('data-color-id'),
            modelID
          }
        });
        window.dispatchEvent(event);

        ProductConfigurator.setColor(el.getAttribute('data-color'));
      });

      if (el.checked) {
        // let event = new Event('change');
        // el.dispatchEvent(event);
        ProductConfigurator.setColor(el.getAttribute('data-color'));
      }
    });
  }
}

export default WallConfiguratorProduct;
