const numberInputButtons = (containerID, lessBTN, moreBTN) => {
  const elem = document.getElementById(containerID);
  if (elem) {
    const input = elem.querySelector('input[type="number"]');
    const minInputValue = input.getAttribute('min');
    const lessButton = elem.querySelector(lessBTN);
    const moreButton = elem.querySelector(moreBTN);

    if (elem && lessButton && moreButton) {
      const customEvent = value => {
        const event = new CustomEvent('newInputValue', { detail: value });
        input.dispatchEvent(event);
      };

      lessButton.addEventListener('click', () => {
        if (input.value > minInputValue) input.value--;
        customEvent(input.value);
      });

      moreButton.addEventListener('click', () => {
        input.value++;
        customEvent(input.value);
      });

      input.addEventListener('input', () => {
        const inputValue = parseInt(input.value);
        if (inputValue < minInputValue || !Number.isInteger(inputValue)) input.value = minInputValue;
        customEvent(input.value);
      });
    }
  }
};

export default numberInputButtons;
