import ConfiguratorProduct from './../ConfiguratorProduct';

class InspirationProductConfigurator {
  constructor() {
    window.addEventListener('inspirationLoaded', () => {
      this.configuredProduct();
    });
  }

  configuredProduct() {
    const canvas = document.getElementById('single-product-configurator');
    const productID = canvas.getAttribute('data-product-id');
    const productColor = canvas.getAttribute('data-product-color');
    const productColorID = canvas.getAttribute('data-product-color-id');
    const productConfigurator = new ConfiguratorProduct(canvas, productID);
    const event = new CustomEvent('selectedColor', {
      detail: {
        selectedColorID: productColorID,
        productID
      }
    });
    window.dispatchEvent(event);
    productConfigurator.setColor(productColor);
  }
}

export default InspirationProductConfigurator;
