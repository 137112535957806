import $ from 'jquery';
import SingleProductConfigurator from './../configurator/singleProductConfigurator';
import ConfiguredProduct from './../configurator/configuredProduct';
import ProductGallery from './single-product/ProductGallery';
import InspirationProductConfigurator from './../inspirations/InspirationProductConfigurator';

class RemoveFromCart {
  constructor(button) {
    this.$buttons = document.querySelectorAll(button);
    this.removeFromCart();
  }
  removeFromCart() {
    for (const btn of this.$buttons) {
      btn.addEventListener('click', e => {
        e.preventDefault();
        const productKey = btn.getAttribute('data-product-key');

        $.ajax({
          type: 'POST',
          url: jsData.ajaxUrl,
          data: {
            action: 'remove_product_from_cart',
            product_key: productKey
          },
          success: function(res) {
            if (res) {
              $(document.body).trigger('wc_fragment_refresh');
            }
          },
          error: function(error) {
            console.log(error);
          }
        });
      });
    }
  }
}

$('body')
  .on('wc_fragment_refresh', function() {
    new RemoveFromCart('.remove-from-card');
  })
  .on('added_to_cart', () => {
    new RemoveFromCart('.remove-from-card');
  });

window.addEventListener('DOMContentLoaded', () => {
  new RemoveFromCart('.remove-from-card');
  new InspirationProductConfigurator();
  new ProductGallery();

  const singleProductConfigurator = document.getElementById('single-product-configurator');
  if (singleProductConfigurator)
    new SingleProductConfigurator(singleProductConfigurator, singleProductConfigurator.getAttribute('data-product-id'));

  const addToCartForm = document.querySelector('form.cart[data-type="configurator"]');
  if (addToCartForm) new ConfiguredProduct().addToCart(addToCartForm);
});
