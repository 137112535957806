import { createLoader } from './../helper';
import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';

class SingleInspiration {
  constructor() {
    this.inspirationsContainer = document.getElementById('inspirations');
    this.popup = document.getElementById('single-inspiration-popup');
    this.Html = document.querySelector('html');
    if (this.inspirationsContainer) this.init();
  }

  init() {
    this.getInspiration();
  }

  openPopup() {
    this.Html.classList.add('overflow-hidden');
    this.popup.classList.add('open');
  }

  closePopup() {
    const closeBTN = document.getElementById('close-popup');
    closeBTN.addEventListener('click', () => {
      this.Html.classList.remove('overflow-hidden');
      this.popup.classList.remove('open');
    });
  }

  getInspiration() {
    document.body.addEventListener('click', e => {
      const target = e.target;
      if (target.classList.contains('inspiration-loop')) {
        e.preventDefault();

        const loader = createLoader();

        const inspirationID = target.getAttribute('data-id');
        const inspirationIndex = target.getAttribute('data-index');

        const formData = new FormData();
        formData.append('action', 'ajaxGetInspiration');
        formData.append('postsPerPage', '-1');
        formData.append('inspirationID', inspirationID);
        formData.append('index', inspirationIndex);

        fetch(wc_add_to_cart_params.ajax_url, {
          method: 'post',
          body: formData
        })
          .then(resp => resp.json())
          .then(resp => {
            this.popup.querySelector('#single-inspiration-popup-content').innerHTML = resp;
            this.openPopup();
            this.closePopup();
            this.thumbnailsSlider(inspirationIndex);
            loader.remove();
          })
          .then(() => {
            const event = new Event('inspirationLoaded');
            window.dispatchEvent(event);
          });
      }
    });
  }

  thumbnailsSlider(initialSlide) {
    const slider = $('#inspiration-thumbs');
    const currentSlideContainer = document.getElementById('inspiration-slides-current');
    currentSlideContainer.innerHTML = parseInt(initialSlide) + 1;

    slider
      .slick({
        slidesToShow: 6,
        arrows: false,
        initialSlide: initialSlide,
        draggable: false,
        touchMove: false
      })
      .on('afterChange', (e, slick, currentSlide) => {
        const event = new Event('inspirationLoaded');
        window.dispatchEvent(event);
        currentSlideContainer.innerHTML = currentSlide + 1;
      });

    slider[0].querySelectorAll('.inspiration-thumbnail').forEach(el => {
      el.addEventListener('click', () => {
        if (!el.closest('.slick-current')) {
          const formData = new FormData();
          formData.append('action', 'ajaxGetInspirationProduct');
          formData.append('productID', el.getAttribute('data-product-id'));
          formData.append('inspirationID', el.getAttribute('data-inspiration-id'));

          const loader = createLoader();

          fetch(wc_add_to_cart_params.ajax_url, {
            method: 'post',
            body: formData
          })
            .then(resp => resp.json())
            .then(resp => {
              const productContainer = document.getElementById('inspiration-product-container');
              const largeGalleryImageContainer = document.getElementById('inpiration-gallery-large-image');
              const largeImage = el.getAttribute('data-large-image-url');
              const index = el.getAttribute('data-index');

              productContainer.innerHTML = resp;
              largeGalleryImageContainer.style.backgroundImage = `url(${largeImage})`;
              slider.slick('slickGoTo', index);

              loader.remove();
            });
        }
      });
    });
  }
}

export default SingleInspiration;
