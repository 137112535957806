import ConfiguratorProduct from './../ConfiguratorProduct';

class SingleProductConfigurator {
  constructor(canvas, productID) {
    this._canvas = canvas;
    this._productID = productID;
    this.priceElem = document.getElementById('total-price');
    this.defaultPrice = this.priceElem.getAttribute('data-default-price');
    this.singlePriceHolder = document.querySelector('.price-number');
    this.productConfigurator = new ConfiguratorProduct(this._canvas, this._productID);
    this.quantityInput = document.getElementById('quantity');
    this.init();
  }

  init() {
    this.forcePriceCount();
    this.inputs();
  }

  setMinProductOrder(colorInput) {
    let tabsPanel = colorInput.closest('.tabs-panel.color');

    if (tabsPanel) {
      let minAmount = tabsPanel.querySelector('.tabs-button.active').getAttribute('data-min-amount') || 1;
      this.quantityInput.setAttribute('min', minAmount);

      if (this.quantityInput.value < parseInt(minAmount)) {
        this.quantityInput.value = parseInt(minAmount);
      }
    }

    setTimeout(() => this.forcePriceCount(), 10);
  }

  forcePriceCount() {
    const event = new CustomEvent('newInputValue', { detail: this.quantityInput.value });

    this.quantityInput.dispatchEvent(event);
  }

  async inputs() {
    if (!window.location.search) {
      document.querySelector('.colors label:first-child input').checked = true;
    }
    document.querySelectorAll('input[name="pa_kolor"]').forEach(input => {
      input.addEventListener('change', () => {
        const selectedColorValue = input.getAttribute('data-color');
        const selectedColorID = input.value;
        const productID = input.getAttribute('data-product-id');
        const additionalPrice = input.getAttribute('data-additional-price');
        let productPrice = 0;

        this.setMinProductOrder(input);

        if (additionalPrice) {
          productPrice = parseFloat(this.defaultPrice) + parseFloat(additionalPrice);
          productPrice = productPrice.toFixed(2);
          this.priceElem.setAttribute('data-default-price', productPrice);
        } else {
          productPrice = parseFloat(this.defaultPrice);
          this.priceElem.setAttribute('data-default-price', productPrice);
        }

        this.singlePriceHolder.textContent = `${productPrice} `;

        const event = new CustomEvent('selectedColor', {
          detail: {
            selectedColorID,
            productID,
            additionalPrice
          }
        });
        window.dispatchEvent(event);

        this.productConfigurator.setColor(selectedColorValue);

        if (history.pushState) {
          const newurl =
            window.location.protocol +
            '//' +
            window.location.host +
            window.location.pathname +
            `?pa_kolor=${selectedColorID}`;

          window.history.pushState(
            {
              path: newurl
            },
            '',
            newurl
          );
        }
      });

      if (input.checked) {
        this.setMinProductOrder(input);
        this.productConfigurator.setColor(input.getAttribute('data-color'));

        const selectedColorID = input.value;
        const productID = input.getAttribute('data-product-id');
        const event = new CustomEvent('selectedColor', {
          detail: {
            selectedColorID,
            productID
          }
        });
        window.dispatchEvent(event);
      }
      setTimeout(() => this.forcePriceCount(), 100);
    });
  }
}

export default SingleProductConfigurator;
