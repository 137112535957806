//js
require('./js/ie11');
require('./js/slider');
require('./js/scroll');
require('./js/helper');
require('./js/navigation');
require('./js/maps');
require('./js/main');
require('./js/woocommerce/main');

//fonts
require('./fonts/posterama/stylesheet.css');

// css
import 'normalize.css';
require('./sass/style.scss');
