import { createLoader, isPhone } from './helper';

class ConfiguratorProduct {
  constructor(canvas, productID) {
    this.canvas = canvas;
    this.productID = productID;

    if (this.canvas && this.productID) {
      this.container = this.canvas.parentElement;
      this.ctx = this.canvas.getContext('2d');
      this.canvas.height = isPhone() ? 500 : this.container.clientHeight;
      this.canvas.width = isPhone() ? 600 : this.container.clientWidth;
      this.imagePosX = 0;
      this.imagePosY = 0;
      this.imageType = null;
      this.error = false;
      this.imageLoaded = false;
      this.imageURL = '';
      this.getProductImage();
    }
  }

  // async getProductImage() {
  //   const formData = new FormData();

  //   const loader = createLoader(this.container);

  //   formData.append('action', 'getSingleConfiguratingProduct');
  //   formData.append('productID', this.productID);

  //   window.addEventListener('selectedColor', async e => {
  //     const { selectedColorID } = e.detail;
  //     formData.append('selectedColorID', selectedColorID);

  //     return await fetch(wc_add_to_cart_params.ajax_url, {
  //       method: 'post',
  //       body: formData
  //     })
  //       .then(res => res.json())
  //       .then(({ error, imageURL, imageType }) => {
  //         this.error = error;
  //         imageType != this.imageType && this.loadImage(imageURL);
  //         loader.remove();
  //       });
  //   });
  // }

  async getProductImage() {
    const formData = new FormData();

    const loader = createLoader(this.container);

    formData.append('action', 'getSingleConfiguratingProduct');
    formData.append('productID', this.productID);

    return await fetch(wc_add_to_cart_params.ajax_url, {
      method: 'post',
      body: formData
    })
      .then(res => res.json())
      .then(({ error, imageURL, imageType }) => {
        this.error = error;
        imageType != this.imageType && this.loadImage(imageURL);
        loader.remove();
      });
  }

  setColor(color) {
    if (color) {
      const set = () => {
        if (this.productImage != undefined) {
          this.ctx.fillStyle = color;
          this.ctx.fillRect(this.imagePosX, this.imagePosY, this.productImage.width, this.productImage.height);
          this.ctx.stroke();
          this.ctx.drawImage(this.productImage, this.imagePosX, this.imagePosY);
        }
      };

      window.addEventListener('productConfigiratorImageIsLoaded', () => set());
      this.imageLoaded && set();
    }
  }

  async loadImage(imageURL = null) {
    if (!this.error && this.canvas) {
      this.ctx.fillStyle = '#fff';
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

      this.productImage = new Image();

      if (imageURL) {
        this.productImage.src = imageURL;
      } else {
        await this.getProductImage().then(res => {
          if (res) {
            this.productImage.src = res.imageURL;
            this.imageURL = res.imageURL;
            this.imageType = res.imageType;
          }
        });
      }

      this.productImage.addEventListener('load', () => {
        this.imagePosX = parseInt(this.canvas.width / 2 - this.productImage.width / 2);
        this.imagePosY = parseInt(this.canvas.height / 2 - this.productImage.height / 2);

        this.ctx.drawImage(this.productImage, this.imagePosX, this.imagePosY);

        const event = new CustomEvent('productConfigiratorImageIsLoaded', {
          imageType: this.imageType
        });
        window.dispatchEvent(event);

        this.imageLoaded = true;
      });
    } else {
      console.log(this.error);
    }
  }
}

export default ConfiguratorProduct;
