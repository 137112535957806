class VisualAccordion {
  constructor() {
    this.container = document.querySelector('.visual-accordion');
    if (this.container) {
      this.images = document.querySelectorAll('.visual-accordion .image');
      this.texts = document.querySelectorAll('.visual-accordion .text');
      this.init();
      this.setActive(0);
    }
  }

  init() {
    let isPause, tick, percentTime;
    const slideTime = 8;
    const progress = document.getElementById('accordion-progress');

    const startProgressbar = () => {
      resetProgressbar();
      percentTime = 0;
      isPause = false;
      tick = setInterval(interval, 10);
    };

    const interval = () => {
      if (isPause === false) {
        percentTime += 1 / slideTime;
        progress.style.width = percentTime + '%';
        if (percentTime >= 100) {
          startProgressbar();
          const active = document.querySelector('.visual-accordion .image.active');
          const activeIndex = [...this.images].indexOf(active);
          if (this.images[activeIndex + 1]) this.setActive(activeIndex + 1);
          else this.setActive(0);
        }
      }
    };

    const resetProgressbar = () => {
      progress.style.width = 0 + '%';
      clearTimeout(tick);
    };

    this.texts.forEach(text => {
      text.addEventListener('mouseenter', () => {
        if (text.classList.contains('active')) isPause = true;
      });
      text.addEventListener('mouseleave', () => {
        isPause = false;
      });
    });

    startProgressbar();
  }

  setActive(index) {
    this.images.forEach(el => el.classList.remove('active'));
    this.texts.forEach(el => el.classList.remove('active'));
    this.images[index].classList.add('active');
    this.texts[index].classList.add('active');
  }
}

export default VisualAccordion;
