import CoreAnimation from './animations/CoreAnimation';

const togglePanels = () => {
  const containers = document.querySelectorAll('.toggle-panel');

  if (containers) {
    containers.forEach(container => {
      const toggleButton = container.querySelector('.toggle-button');

      toggleButton.addEventListener('click', () => {
        const toggleContainer = toggleButton.closest('.toggle-panel');
        toggleContainer.classList.toggle('active');
        CoreAnimation.slideToggle(toggleContainer.querySelector('.toggle-content'));
      });
    });
  }
};

export default togglePanels;
