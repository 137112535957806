import { b64toBlob, createLoader, createNotice } from './../helper';

class ConfiguredProduct {
  constructor() {
    this.canvas = document.getElementById('single-product-configurator');
    this.isSent = false;
  }

  addToCart(form) {
    this.inputs = document.querySelectorAll('input[name="pa_kolor"]');

    if (this.inputs.length) {
      form.addEventListener('submit', e => {
        e.preventDefault();

        if ([...this.inputs].find(input => input.checked)) {
          this.isSent = true;
          const formData = new FormData(form);

          formData.append('action', 'addConfiguredProductToCart');
          formData.append('configuredImage', this.getConfiguredImage(), `configured-product-${Date.now()}.jpg`);

          const loader = createLoader(form.querySelector('button[type="submit"]'));

          return fetch(wc_add_to_cart_params.ajax_url, {
            method: 'POST',
            body: formData
          })
            .then(res => res.json())
            .then(res => {
              if (res.error == false) {
                createNotice(res.message, 'succes');
                loader.remove();
                this.isSent = false;

                let event = new Event('wc_fragment_refresh');
                document.body.dispatchEvent(event);
              }
            });
        }
      });
    }
  }

  getConfiguredImage() {
    const configuredImage = this.canvas.toDataURL('image/jpeg', 1);
    return b64toBlob(configuredImage);
  }
}

export default ConfiguredProduct;
